import React from 'react'

import About from '../components/About'
import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'

function AboutPage() {
  return (
    <Layout>
      <SEO title='About' />
      <PageContent>
        <Header />
        <About />
        <FooterMenu />
      </PageContent>
    </Layout>
  )
}

export default AboutPage
