import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import Person from '../../images/person.png'
import Medium from '../../images/svg/social/medium.svg'
import Twitter from '../../images/svg/social/twitter.svg'

function About() {
  const crowdwall = (
    <article id='crowdwall' className='crowdwall'>
      <div className='heading'>
        What is a <span className='highlight-primary'>crowdwall</span>?
      </div>
      <br />
      <p className='para-text'>
        A “crowdwall” is new concept, similar to traditional paywalls you see at
        news websites. The difference here is there is one total goal that
        everyone contributes to. When the goal is reached, the content is made
        freely available to anyone and everyone permanently, with no additional
        costs to users.
      </p>
      <br />
      <p className='para-text'>
        Think of it like a Kickstarter “stretch goal” for digital media, except
        at The Biz, the content is unlocked proportionally with every payment.
        That means if you contribute toward 1% of the goal, then 1% of the audio
        becomes unlocked for listening. Cool!
      </p>
    </article>
  )

  return (
    <Row className='about-section'>
      <div>
        <div className='heading'>A Crowdfunded Bitcoin Podcast</div>
        <div className='divider' />
        <p className='para-text'>
          Episodes of <i>The Biz with John Carvalho</i> are each locked behind a
          crowdwall. A crowdwall is new concept, similar to traditional paywalls
          you see at news websites. The difference here is there is one total
          goal that everyone contributes to. When the goal is reached, the
          content is made freely available to anyone and everyone permanently,
          with no additional costs to users.
        </p>
        <br />
        <p className='para-text'>
          Additionally, 50% of all proceeds for the first season of The Biz will
          be donated to a cause chosen by each guest. We want to demonstrate
          that not only can you monetize content in a self-sovereign way, but
          that you can give donations instead of accept sponsorships. Hopefully
          this at least makes paying for your content a little more palatable!
        </p>
        <br />
        <p className='para-text'>
          We will use <b className='highlight'>thebiz.pro</b> to experiment with
          the user experience of paying for digital content, so I am hoping for
          your support, and a little patience. If Bitcoiners want a way to opt
          out of the advertising-funded, privacy-compromising web, then you are
          supporting the right project!
        </p>
        <br />
        <Row className='visible-md2'>
          <Col md={12}>{crowdwall}</Col>
        </Row>
        <br />
        <div className='heading'>No Sponsors, No Ads, No Bullshit</div>
        <div className='divider' />
        <p className='para-text'>
          This podcast has no ads and no sponsors, allowing relatively unbiased
          content. Each episode is locked behind a{' '}
          <span className='highlight-primary'>“crowdwall”</span> and will become
          incrementally available as BTC payments are made.
        </p>
        <br />
        <p className='para-text'>
          Once the full goal for an episode is reached, each episode will be
          unlocked publicly for everyone, and distributed to popular podcast
          platforms for free.
        </p>
      </div>
      <div>
        <div className='heading'>Hosted by John Carvalho</div>
        <div className='divider' />
        <Row className='row1'>
          <Col sm={12} md={5} className='row1-1'>
            <img src={Person} alt='person' className='person-img' />
            <div className='social-about'>
              <a
                href='https://twitter.com/bitcoinerrorlog'
                target='_blank'
                rel='noreferrer'
              >
                <span>
                  <Twitter />
                  <span>@bitcoinerrorlog</span>
                </span>
              </a>
              <a
                href='https://bitcoinerrorlog.medium.com'
                target='_blank'
                rel='noreferrer'
              >
                <span>
                  <Medium />
                  <span>Medium blog</span>
                </span>
              </a>
            </div>
          </Col>
          <Col sm={12} md={7}>
            <p className='para-text'>
              John has been a full-time Bitcoiner since 2013, contributing to
              various Bitcoin projects, communities, startups, and podcasts.
            </p>
            <br />
            <p className='para-text'>
              John’s career experience includes branding, marketing, product
              design and strategy, and business development.
            </p>
            <br />
            <p className='para-text'>
              John is CEO of a new Bitcoin company in stealth mode, working to
              create an alternative digital economy for Bitcoiners.
            </p>
            <br />
          </Col>
        </Row>
        <Row className='hidden-md2'>
          <Col md={12}>{crowdwall}</Col>
        </Row>
      </div>
    </Row>
  )
}

export default About
